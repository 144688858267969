import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import PageTemplate from "../../templates/page"
import HomeTemplate from "../../templates/home"
import { pageData } from "../../fragments/data"
import LoadingBars from "./Loading";

const cleanedPageData = pageData.replace(/WPGraphQL_/g, "")

const GET_POSTS = gql`
  query($postID: ID!) {
    templateData: page(idType: DATABASE_ID, id: $postID) {
      ${cleanedPageData}
    }
  }
`

const PreviewPost = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })


  return (
    <>
      {loading && <LoadingBars />}


      {data && renderTemplate(data.templateData)}

      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre> }

      
    </>
  )
}

export default PreviewPost

const renderTemplate = page => {

  switch (page.pageTemplate) {
    case "home":
      return <HomeTemplate pageContext={page} />

    default:
      return <PageTemplate pageContext={page} />
  }
}
