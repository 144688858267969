import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import PageTemplate from "../../templates/story"
import { storiesData } from "../../fragments/data"
import LoadingBars from "./Loading";

const cleanedPageData = storiesData.replace(/WPGraphQL_/g, "")

const GET_POSTS = gql`
  query($postID: ID!) {
    templateData: post(idType: DATABASE_ID, id: $postID) {
      ${cleanedPageData}
    }
  }
`
const PreviewPost = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  return (
    <>
      {loading && <LoadingBars />}

      {data && <PageTemplate pageContext={data.templateData} />}

      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre> }

      
    </>
  )
}

export default PreviewPost
