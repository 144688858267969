import React from "react"

import { InternalTemplate } from "../components/InternalTemplate"
import { parseBlockContent } from "utils/customBlocks"

const InternalPage = ({ pageContext }) => {
  const {
    title,
    content,
    textOptions,
    header,
    relatedContent,
    avBulletPoints,
  } = pageContext

  const customContent = content ? parseBlockContent(content) : null

  return (
    <InternalTemplate
      content={customContent}
      textOptions={textOptions}
      internal
      title={title}
      relatedContent={relatedContent}
      headerImg={header ? header.headerImage : false}
      bulletPoints={avBulletPoints?.bulletPointItems}
    />
  )
}

export default InternalPage
