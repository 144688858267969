import React from "react"
import loadingBars from "./images/bars.svg"

const Loading = () => {
  return (
    <>
      <div style={{ marginTop: "40vh", textAlign: "center" }}>
        <img
          width="50"
          src={loadingBars}
          style={{ display: "inline-block" }}
          alt=""
        />
        <div style={{ fontSize: "14px", textAlign: "center", color: "#5A5A5A" }}>
          Loading Preview
        </div>
      </div>
    </>
  )
}

export default Loading
