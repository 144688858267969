import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import CasestudyTemplate from "../../templates/casestudy"
import { caseStudyData, relatedPage } from "../../fragments/data"
import LoadingBars from "./Loading";

const GET_POSTS = gql`
  query($postID: ID!) {
    templateData: casestudy(idType: DATABASE_ID, id: $postID) {
      ${caseStudyData}
      relatedContent {
        relatedHeader
        relatedPages {
          ... on Casestudy {
            ${relatedPage}
          }
        }
      }
    }
  }
`

const PreviewPost = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  return (
    <>
      {loading && <LoadingBars />}

      {data && <CasestudyTemplate pageContext={data.templateData} />}


      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre> }

      
    </>
  )
}

export default PreviewPost
