const imgObject = `
  sourceUrl
  srcSet
  mediaDetails {
    height
    width
  }
`
const teaserObject = `
  title
  excerpt
  link
  featuredImage {
    sourceUrl
    srcSet
    mediaDetails {
      width
      height
    }
  }
  author {
    name
  }
`

const caseStudyData = `
  id
  title
  uri
  link
  content
  excerpt,
  categories(where: {parent: 15}) {
    nodes {
      name
    }
  }
  header {
    headerImage {
      ${imgObject}
    }
  }
  featuredImage {
    sourceUrl
    srcSet
    mediaDetails {
      height
      width
    }
  }
  textOptions {
    introStatement
  }

`

const storiesData = `
  id
  title
  uri
  link
  content
  excerpt,
  header {
    headerImage {
      ${imgObject}
    }
  }
  featuredImage {
    sourceUrl
    srcSet
    mediaDetails {
      height
      width
    }
  }
  textOptions {
    introStatement
  }

  avBulletPoints {
    bulletPointItems {
      text
    }
  }

`

const pageData = `
  id
  title
  uri
  link
  content
  pageTemplate
  header {
    headerImage {
      ${imgObject}
    }
  }
  featuredImage {
    ${imgObject}
  }
  textOptions {
    introStatement
  }
  caseStudies {
    caseStudies {
      ... on WPGraphQL_Casestudy {
        title
        link
        excerpt
        categories(where: {parent: 15}) {
          nodes {
            name
            link
          }
        }
        textOptions {
          homeStatement
        }
        homePageOptions {
          homePageThumbnail {
            ${imgObject}
          }
        }
        featuredImage {
          ${imgObject}
        }
      }
    }
  }

  stories {
    stories {
      ... on WPGraphQL_Post {
        ${teaserObject}
      }
    }
  }


  avBulletPoints {
    bulletPointItems {
      text
    }
  }
 `

const relatedPage = `
  title
  link
  featuredImage {
    ${imgObject}
  }
  categories(where: {parent: 15}) {
    nodes {
      name
    }
}`

module.exports = {
  caseStudyData,
  pageData,
  relatedPage,
  storiesData,
}
